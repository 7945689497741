import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#fff',
          fontWeight: '600',
          borderRadius: '10px',
        },
        page: {
          '&.Mui-selected': {
            background: 'linear-gradient(0deg, #7150D0 0%, #AE92FF 90%)',
            color: 'white',
            fontWeight: '600',
            borderRadius: '10px',
            border: '1px solid #7150D0',
          },
          '&:hover': {
            backgroundColor: 'lightblue', // Hover background color
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
            borderRadius: '10px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
            borderRadius: '10px',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
            borderRadius: '10px',
          },
          '& .MuiOutlinedInput-input': {
            backgroundColor: '#f0f0f0',
            color: '#000000',
            borderRadius: '10px',

          },
          '& .MuiOutlinedInput-input::placeholder': {
            color: '#888888',
          },

          '& .MuiSelect-select': {
            padding: '8px 8px',
            borderRadius: '10px',
          },

          '&.Mui-focused .MuiSelect-select': {
            borderRadius: '10px',
            backgroundColor: '#8A79BC',
            color: '#fff',
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#3D3D3D',
          color: 'white',
          borderRadius: '10px',
          border: '1px solid #929292',
        },
        arrow: {
          color: '#3D3D3D',

          '&::before': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '100%',
            border: '1px solid #929292', // Change this to your desired border color
            transform: 'rotate(45deg)', // Adjust to fit the triangle shape
          },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          color: '#fff',
          textTransform: 'none',
          '&.Mui-selected': {
            color: '#CCBAFF', // Change text colorbackgroundColor: 'blue', // Change background color
            fontWeight: '600',
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        indicator: {
          textTransform: 'none',
          color: '#fff',
          backgroundColor: '#CCBAFF', // Change indicator color
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: '#3D3D3D',
          color: '#fff',
          fontWeight: '600',
          fontSize: '0.8rem',
          fontFamily: 'Open Sans',
          '&.Mui-expanded': {
            backgroundColor: '#3D3D3D',
            color: '#fff',
            fontWeight: '600',
            fontSize: '0.8rem',
            fontFamily: 'Open Sans',
            Margin: '0.1rem !important',
          },
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: '#3D3D3D',
          color: '#fff',
          fontSize: '0.8rem',
          fontFamily: 'Open Sans',
        },
      },
    },

    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: '#8A79BC',
          color: 'white',
          fontSize: '0.7rem',
        },
      },
    },

  },
});