import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ResetPassword from '../Pages/Admin/resetPassword';
import PrivacyPolicy from '../Pages/User/privacyPolicy';
import TermsConditions from '../Pages/User/termsConditions';
import CookiePolicy from '../Pages/User/cookiePolicy';
import DeleteUser from '../Pages/User/deleteUser';
import Otp from '../Pages/User/otp';
import Promo from '../Pages/Promo/index';
import NotFound from '../Pages/User/notFound';

const CommonRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path={"/"} element={<Promo />} />
        <Route path={"/ResetPassword/:token"} element={<ResetPassword />} />
        <Route path={"/PrivacyPolicy"} element={<PrivacyPolicy />} />
        <Route path={"/TermsConditions"} element={<TermsConditions />} />
        <Route path={"/CookiePolicy"} element={<CookiePolicy />} />
        <Route path={"/DeleteUser"} element={<DeleteUser />} />
        <Route path={"/Otp"} element={<Otp />} />
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </div>
  )
}

export default CommonRoutes
