export const ApiConfig = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    AuthToken: process.env.REACT_APP_AUTH_TOKEN,
    CryptoKey: process.env.REACT_APP_CRYPTO_KEY,
    // firebase config
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    recaptchaKey: process.env.REACT_APP_RECAPTHA_KEY,
}