import { globalColor } from './globalColor';

//Login Pages Styles
export const loginStyles = {
    mainDiv: {
        backgroundColor: globalColor.bgPrimary,
        color: globalColor.textPrimary,
        height: '100vh',
    },
    mainLogoDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    heading: {
        color: globalColor.textPrimary,
        fontWeight: '600',
        fontSize: '1.2rem',
        textAlign: 'center',
    },
    labelText: {
        fontSize: '0.9rem',
        // color: globalColor.textPrimary + ' !important',
        fontWeight: globalColor.textSemiBold,
        paddingTop: '1rem !important',
        color: '#fff'
    },
    text_white: {
        color: '#fff'
    },
    warningText: {
        color: '#FF6E30',
        fontWeight: globalColor.textSemiBold,
        textAlign: 'center',
        paddingTop: '1rem',
        cursor: 'pointer'
    },
    themeButton: {
        display: 'block',
        margin: 'auto',
        color: globalColor.textPrimary,
        marginTop: '2rem',
        background: 'linear-gradient(38.23deg, #7150D0 15.58%, #AE92FF 83%)',
        fontWeight: globalColor.textSemiBold,
        padding: '0.5rem 4rem',
        borderRadius: '1.5rem',
        '&:hover': {
            background: 'linear-gradient(38.23deg, #7150D0 15.58%, #AE92FF 83%)',
        }
    },
    leftshadows: {
        background: '#4527A0',
        filter: 'blur(105px)',
        position: 'absolute',
        width: '250px',
        height: '250px',
        left: '-125px',
        top: '-125px',
        borderRadius: '50px',
    },
    rightshadows: {
        background: '#4527A0',
        filter: 'blur(65px)',
        position: 'absolute',
        width: '250px',
        height: '250px',
        bottom: '0px',
        right: '0px',
        borderRadius: '50px',
    },
    selectInput: {
        color: globalColor.textPrimary,
        fontWeight: globalColor.textSemiBold,
        height: '3.5rem',
        fontSize: '0.9rem',
        backgroundColor: '#fff',
        
    },
};

//All Pages Common Styles
export const commonStyles = {
    textCenter: {
        textAlign: 'center',
    },

    pt1: {
        paddingTop: '1rem',
    },

    pt2: {
        paddingTop: '2rem',
    },

    pt3: {
        paddingTop: '3rem',
    },

    pt4: {
        paddingTop: '4rem',
    },

    pt5: {
        paddingTop: '5rem',
    },

    px1: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },

    pb1: {
        paddingBottom: '1rem',
    },

    mt1: {
        marginTop: '1rem',
    },

    py1: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #3D3D3D',
    },

    mx1: {
        margin: '0 1rem'
    },
    flex_center_center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    flex_center_start: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },

    flex_center_between: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    flex: {
        display: 'flex',
    },

    outlet_root: {
        marginTop: '0.5rem',
        background: '#2D2D2D',
        border: ' 1px solid #3D3D3D',
        borderRadius: '1rem',
        padding: '1rem',
        color: globalColor.textPrimary,
        height: 'calc(100vh - 13vh - 1.5rem)',
        overflow: 'auto',

    },

    heading: {
        fontWeight: globalColor.textSemiBold,
    },

    heading2: {
        fontWeight: globalColor.textSemiBold,
        marginTop: '1rem',
    },

    subHeading: {
        color: globalColor.textPrimary,
        fontWeight: globalColor.textSemiBold,
        marginLeft: '1rem',
    },

    subHeading2: {
        color: globalColor.textPrimary,
        fontWeight: globalColor.textSemiBold,
        marginLeft: '1rem',
        fontSize: '0.9rem',
    },

    lottieDiv: {
        marginTop: '2rem',
        backgroundColor: '#1D1B29',
        borderRadius: '1rem',
        padding: '1rem'
    },

    loaderDiv: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'block',
        zIndex: '999',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },

    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },

    verifiedButton: {
        borderRadius: '5px',
        fontSize: '0.9rem',
        padding: '0.2rem 1rem',
        color: '#419C6E',
        fontWeight: '600',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#419C6E',
            color: '#D8FFEB',
        }
    },

    pendingButton: {
        borderRadius: '5px',
        fontSize: '0.9rem',
        padding: '0.2rem 1rem',
        color: '#FF6E30',
        fontWeight: '600',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#FF6E30',
            color: '#FFD8D8',
        }
    },

    delineButton: {
        borderRadius: '5px',
        fontSize: '0.9rem',
        padding: '0.2rem 1rem',
        color: '#E72222',
        fontWeight: '600',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#E72222',
            color: '#FFE3E3',
        }
    },

    menuItemText: {
        paddingLeft: '1rem',
        fontWeight: globalColor.textSemiBold,
    },

    warnIcon: {
        color: '#FFCB45',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.2)',
        }
    },

    blockIcon: {
        color: '#FF9036',
        cursor: 'pointer',
        marginLeft: '0.5rem',
        '&:hover': {
            transform: 'scale(1.2)',
        }
    },

    removeIcon: {
        color: '#FF2121',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.2)',
        },
    },

    deleteIcon: {
        color: '#FF2121',
        cursor: 'pointer',
        marginLeft: '0.5rem',
        '&:hover': {
            transform: 'scale(1.2)',
        },
    },

    removedisIcon: {
        color: '#FF2121',
        cursor: 'not-allowed',
        opacity: '0.5',
    },

    toastSuccess: {
        draggable: true,
        theme: "colored",
        autoClose: 2000,
        style: {
            backgroundColor: "#8A79BC",
            color: "white",
        }
    },

    toastMessage: {
        draggable: true,
        theme: "colored",
        autoClose: false,
        position: "top-center",
        style: {
            backgroundColor: "#8A79BC",
            color: "white",
        },
    },

    modelrootDiv: {
        textAlign: 'center', background: '#484848', color: '#fff', minWidth: '500px'
    },

    userName: {
        paddingLeft: '0.5rem',
        fontSize: '14px',
        width: '90px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
    },

    submitButton: {
        marginTop: '1rem',
        borderRadius: '1rem',
        fontSize: '0.9rem',
        padding: '0.3rem 2rem',
        color: '#FFF',
        background: 'linear-gradient(0deg, #7150D0 0%, #AE92FF 90%)',
        fontWeight: '600',
        textTransform: 'uppercase',
        transition: 'background 0.3s ease',
        border: '2px solid #7150D0',
        '&:hover': {
            background: '#fff',
            color: '#7150D0',
            transition: 'background 0.3s ease',
            border: '2px solid #7150D0',
        }
    },

    submitButton2: {
        borderRadius: '1rem',
        fontSize: '0.9rem',
        padding: '0.3rem 3rem',
        color: '#FFF',
        background: 'linear-gradient(0deg, #7150D0 0%, #AE92FF 90%)',
        fontWeight: '600',
        textTransform: 'uppercase',
        transition: 'background 0.3s ease',
        float: 'right',
        marginLeft: '1rem',
        '&:hover': {
            background: '#fff',
            color: '#7150D0',
            transition: 'background 0.3s ease',
        }
    },

    closeIcon: {
        position: 'absolute !important',
        top: '5px',
        right: '10px',
        color: 'white',
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'scale(1.2) rotate(90deg)',
        }
    },

    closeIcon2: {
        position: 'absolute !important',
        top: '5px',
        right: '5px',
        color: 'black',
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'rotate(90deg)',
        }
    },

    model: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(5px)',
    },

    warnText: {
        color: '#E7B022',
        fontWeight: '600',
        fontSize: '0.9rem',
        background: '#FFFDD9',
        padding: '0.2rem 0.8rem',
        borderRadius: '0.8rem',
        opacity: '0.6',
        cursor: 'not-allowed',
    },

    verifiedText: {
        color: '#419C6E',
        fontWeight: '600',
        fontSize: '0.9rem',
        background: '#D8FFEB',
        padding: '0.1rem 1rem !important',
        borderRadius: '0.8rem',
        opacity: '0.6',
        cursor: 'not-allowed',
        textTransform: 'capitalize',
        '&:hover': {
            background: '#D8FFEB',
            color: '#419C6E',
        }
    },

    unblockText: {
        color: '#E77A22',
        fontWeight: '600',
        fontSize: '0.8rem',
        background: 'transparent',
        padding: '0.1rem 0.8rem',
        borderRadius: '0.8rem',
        cursor: 'pointer',
        textTransform: 'capitalize',
        border: '1px solid #E77A22',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#E77A22',
            border: '1px solid #E77A22',
        }
    },

    DeleteText: {
        color: '#FF2121',
        fontWeight: '600',
        fontSize: '0.8rem',
        background: 'transparent',
        padding: '0.1rem 0.8rem',
        borderRadius: '0.8rem',
        textTransform: 'capitalize',
        border: '1px solid #FF2121',
        transition: 'background 0.3s ease',
        cursor:'not-allowed',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#FF2121',
            border: '1px solid #FF2121',
        }
    },

    blockText: {
        color: '#E77A22',
        fontWeight: '600',
        fontSize: '0.9rem',
        background: ' #FFEAD9',
        padding: '0.2rem 0.8rem',
        borderRadius: '0.8rem',
        opacity: '0.6',
        cursor: 'not-allowed',
    },

    acceptButton: {
        fontSize: '1rem',
        padding: '0.3rem 2rem',
        borderRadius: '1rem',
        margin: '1rem 1rem 0 0',
        color: '#fff',
        fontWeight: '600',
        textTransform: 'capitalize',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: '#419C6E',
            color: '#D8FFEB',
            transform: 'scale(1.1)',
        }
    },

    rejectButton: {
        fontSize: '1rem',
        borderRadius: '1rem',
        padding: '0.3rem 2rem',
        margin: '1rem 1rem 0 0',
        color: '#5E5E5E',
        fontWeight: '600',
        backgroundColor: '#fff',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#5E5E5E',
        }
    },

    editorBox: {
        padding: '0.5rem',
        margin: '0.5rem',
        backgroundColor: '#3D3D3D',
        borderRadius: '0.5rem',
    },

    w50: {
        width: '50%',
        height: '65vh',
        overflowY: 'auto',
    },

    w100: {
        width: '100%',
    },

    themeColor_lg: {
        color: '#7150D0',
        cursor: 'pointer',
    },

    accordianBox: {
        backgroundColor: '#3D3D3D',
        marginTop: '0.5rem',
    },

    dangerText: {
        color: ' #E31D1C',
        cursor: 'pointer',
    },

    ppmainDiv: {
        margin: '1rem',
        backgroundColor: '#3D3D3D',
        padding: '1rem',
        borderRadius: '0.5rem',
        height: '50vh',
        overflowY: 'auto',
        overflowX: 'hidden',
    },

    socialButton: {
        color: '#fff',
        border: '2px solid #fff',
        borderRadius: '2rem',
        padding: '0.3rem 3rem',
        marginRight: '1rem',
        marginTop: '0.5rem',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FFE3E3',
            color: '#E72222',
            fontWeight: '600',
            transform: 'scale(1.1)',
        }
    },

    addBtn: {
        marginTop: '0.5rem',
        minWidth: '50px',
        height: '50px',
        borderRadius: '25px',
        padding: '0rem',
        background: 'linear-gradient(38.23deg, #7150D0 15.58%, #AE92FF 83%)',
        '&:hover': {
            transform: 'scale(1.1)',
            background: 'linear-gradient(38.23deg, #AE92FF 15.58%, #7150D0 83%)',
            color: '#fff',
        }
    },

    themeButton: {
        display: 'block',
        margin: 'auto',
        color: globalColor.textPrimary,
        marginTop: '1rem',
        background: 'linear-gradient(38.23deg, #7150D0 15.58%, #AE92FF 83%)',
        fontWeight: globalColor.textSemiBold,
        padding: '0.5rem 4rem',
        borderRadius: '1.5rem',
        '&:hover': {
            background: 'linear-gradient(38.23deg, #7150D0 15.58%, #AE92FF 83%)',
        }
    },

    calendarIcon: {
        backgroundColor: '#fff',
        color: '#1E2134',
        cursor: 'pointer',
        float: 'right',
        border: '1px solid #fff',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '3rem',
    },

    dashboardCalendar: {
        padding: '0.2rem 0.7rem',
        textAlign: 'center',
        fontSize: '1rem',
        fontWeight: 'bold'
    },

    calendarMainDiv: {
        display: 'flex',
        alignItems: 'center',
        mt: 1
    },

    calendarText: {
        textAlign: 'center',
        ml: 3
    },

    calendar: {
        backgroundColor: '#f0f0f0',
        borderRadius: '1rem'
    },

    privacyMainDiv: {
        background: '#1D1B29',
        color: '#fff',
        height: '100vh',
        overflow: 'auto',
        py: 5
    },

    closebtn: {
        position: 'absolute',
        top: '0',
        right: '0',
        color: 'red',
    },

    modelImg: {
        width: "auto",
        height: "80vh",
        objectFit: "contain"
    },

    helperText: {
        color: 'red',
        fontSize: '0.7rem',
        paddingLeft: '0.5rem'
    },

    backIcon: {
        margin: '0rem 0.5rem',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.2)',
            transition: 'all 0.5s ease',
        }
    },
    
    faqEmpty: {
        fontSize: '1.2rem',
        textAlign: 'center',
        color: '#fff',
        fontWeight: globalColor.textSemiBold,
        border:'1px dashed #fff',
        padding: '1rem',
        borderRadius: '1rem'
    },
    total_user:{
        fontSize: '0.8rem',
        color: '#fff',
    }
}

//Sidenav Styles
export const sidenavStyles = {
    activeDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: '1.5rem',
        color: globalColor.textPrimary,
        fontWeight: globalColor.textSemiBold,
    },
    activeText: {
        paddingLeft: '1rem',
    },
    notActiveDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: '1.5rem',
        color: globalColor.notActiveText,
        textDecoration: 'none !important',
        fontWeight: globalColor.textLinear,

        "&:hover": {
            color: globalColor.textPrimary,
            transform: 'scale(1.05)',
        }
    },

    notActiveText: {
        paddingLeft: '1rem',
    },

    logOutBtn: {
        position: 'absolute',
        bottom: '2rem',
        left: '1.5rem',
        color: '#7C7C7C',
        "&:hover": {
            transform: 'scale(1.1)',
            color: 'red',
        }
    },

    logoutText: {
        pl: 1,
        textTransform: 'capitalize',
    }

}

//rootlayout styles
export const rootLayoutStyles = {
    rootDiv: {
        display: 'grid',
        gridTemplateColumns: '1fr 4fr',
    },

    sidenavDiv: {
        padding: '1rem',
        margin: '1rem 0rem 1rem 1rem',
        borderRadius: '1rem',
        background: '#2D2D2D',
        height: 'calc(100vh - 4vh - 1rem)',
        border: ' 1px solid #3D3D3D',
    },

    contentDiv: {
        margin: '1rem 1rem 1rem 0.5rem',
        height: 'calc(100vh - 4vh - 1rem)',
    },

    headerRoot: {
        padding: '1rem',
        borderRadius: '1rem',
        fontWeight: '600',
        background: '#2D2D2D',
        border: ' 1px solid #3D3D3D',
        height: '9vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
}

export const TableStyles = {
    tableContainer: {
        backgroundColor: '#2D2D2D',
        marginTop: '1rem',
        marginBottom: '1rem',
        boxShadow: '4.93px 9.86px 39.44px 4.93px #138EFF0D',
        // maxHeight: '80%',
    },

    tableBody: {
        backgroundColor: '#3D3D3D',
    },

    tableHead: {
        backgroundColor: '#8A79BC',
    },

    tableHeadFirstCell: {
        color: '#fff',
        borderRadius: '1rem 0 0 0px',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        fontWeight: 'bold',
        textAlign: 'center'
    },

    tableHeadCell: {
        color: '#fff',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        fontWeight: 'bold',
        textAlign: 'center',
        //textWrap: 'noWrap',
    },

    tableHeadLastCell: {
        color: '#fff',
        borderRadius: '0 1rem 0 0px',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        fontWeight: 'bold',
        textAlign: 'center',
        // display: 'flex',
        // alignItems: 'center',
    },

    tableCell: {
        color: '#fff',
        textAlign: 'center',
    },

    profileImg: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        objectFit: 'contain',
        border: '1px solid #fff',
        cursor: 'pointer',
    },

    pointer: {
        cursor: 'pointer',
        '&:hover': {
            color: '#8A79BC',
            fontWeight: 'bold',
        }
    },

    verifiedButton: {
        borderRadius: '25px',
        fontSize: '0.9rem',
        padding: '0rem 1rem',
        backgroundColor: '#D8FFEB',
        color: '#419C6E',
        fontWeight: '600',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#419C6E',
            color: '#D8FFEB',
        }
    },

    pendingButton: {
        borderRadius: '25px',
        fontSize: '0.9rem',
        padding: '0rem 1rem',
        backgroundColor: '#FFD8D8',
        color: '#FF6E30',
        fontWeight: '600',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#FF6E30',
            color: '#FFD8D8',
        }
    },

    delineButton: {
        borderRadius: '25px',
        fontSize: '0.9rem',
        padding: '0rem 1rem',
        backgroundColor: '#FFE3E3',
        color: '#E72222',
        fontWeight: '600',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#E72222',
            color: '#FFE3E3',
        }
    },

    reasonText: {
        color: '#fff',
        fontStyle: 'italic',
        fontSize: '0.8rem',
    },

    toolTipHeading: {
        color: '#fff',
        fontWeight: '600',
        fontSize: '0.8rem',
    },

    toolTipText: {
        color: '#fff',
        fontSize: '0.8rem',
        fontWeight: 'normal',
    },

    textWrap: {
        color: '#fff',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        textAlign: 'center',
        fontSize: '0.8rem',
    },


}

export const dashboard = {
    minAvatar: {
        width: 80,
        height: 80,
    },

    countHeading: {
        pt: 1,
        fontSize: '1.5rem',
        fontWeight: '600'
    },
    countHeading2: {
        pb: 1,
        fontSize: '1.5rem',
        fontWeight: '600'
    },

    viewAll: {
        pb: 1,
        fontSize: '1rem',
        fontWeight: '300',
        color: '#AB8FFD',

    },

    countTitle: {
        pt: 1,
        fontSize: '1rem',
        fontWeight: '400',
        minHeight: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    DonutChartDiv: {
        backgroundColor: '#434343',
        mt: 3,
        borderRadius: '1rem',
        padding: '0.5rem',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
    },
    DonutChartText: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0rem 0.5rem',
        alignItems: 'center'
    },

}

export const NotificationStyles = {
    title: {
        p: 1,
        color: "#fff",
        textAlign: "center",
        fontWeight: "600",
        borderBottom: "1px solid #bfbfbf"
    },

    img: {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        objectFit: "contain",
        border: "1px solid #d9d9d9"
    },

    message: {
        fontSize: "0.9rem",
        fontWeight: "600",
        color: "#fff",
        px: 1,
    },

    time: {
        fontSize: "0.7rem",
        color: "#929292",
        px: 1,
    },

    text_white: {
        color: "#fff",
        fontSize: "1.8rem",
        padding: "0.2rem",
        '&:hover': {
            transition: 'all 0.3s ease',
            backgroundColor: '#f2f2f2',
            color: '#000',
            borderRadius: '50%',
        }
    },

    menuItemBox: {
        py: 1,
        borderBottom: "1px solid #434343"
    },

    TablePagination: {
        padding: "1rem 0rem",
        marginBottom: "1rem"
    }
}