import { ApiConfig } from "../Config/config";
const CryptoJS = require("crypto-js");

export function dateFormat(date) {
  const TodayDate = new Date(date);
  const year = TodayDate.getFullYear();
  const month = String(TodayDate.getMonth() + 1).padStart(2, '0');
  const day = String(TodayDate.getDate()).padStart(2, '0');
  return `${day}-${month}-${year}`
}

export const nameFormatter = (name) => {
  return name?.length > 15 ? `${name?.slice(0, 15)}...` : name
}

export const startDate = () => {
  const TodayDate = new Date();
  const year = TodayDate.getFullYear();
  const month = String(TodayDate.getMonth() + 1).padStart(2, '0');
  const day = String(TodayDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}T00:00:00.000Z`;
}

export const endDate = () => {
  const TodayDate = new Date();
  const year = TodayDate.getFullYear();
  const month = String(TodayDate.getMonth() + 1).padStart(2, '0');
  const day = String(TodayDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}T23:59:59.000Z`;
}

//Encrypt and Decrypt the Data
export const cryptoClient = {
  encryptData: async (data) => {
    try {
      const encryptedString = CryptoJS.AES.encrypt(
        data,
        ApiConfig.CryptoKey
      ).toString();
      return encryptedString;
    } catch (error) {
      console.error(`error occured while encrypting: ${error}`);
    }
  },
  decryptData: async (encryptedString) => {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(
        encryptedString,
        ApiConfig.CryptoKey
      );
      const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
      const decryptedObject = JSON.parse(decryptedString);
      return decryptedObject;
    } catch (error) {
      console.error(`error occured while decrypting: ${error}`);
    }
  },
};
