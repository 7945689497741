import React from 'react';
import CommonRoutes from './commonRoutes'

const routes = () => {
  return (
    <>
      <CommonRoutes />
    </>
  )
}

export default routes
