import React, { useState } from 'react'
import { Box, Grid, InputLabel, Button } from '@mui/material';
import { loginStyles } from '../../Styles/commonStyles';
import { Image } from '../../Utils/imageImport';
import { CustomTextField } from '../../Utils/customField';
import { commonServices } from '../../Services/commonServices';
import { validateEmpty } from '../../Utils/validations';
import { toast } from 'react-toastify';
import { commonStyles } from '../../Styles/commonStyles';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../Components/lotties';

const Otp = () => {
    const [loader, setLoader] = useState(false);
    const Navigate = useNavigate();
    const [error, setError] = useState({
        otp: "",
    });

    const [data, setData] = useState({
        otp: "",
    })
    const userID = sessionStorage.getItem("userID");
    console.log(userID);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const otp = data.get('otp');
        const userID = sessionStorage.getItem("userID").replace(/^"|"$/g, '');
        console.log(userID);
        console.log(otp);
        let payload = {
            "otp": otp,
            "userId": userID
        }
        if (!validateEmpty(otp, "otp", error, setError)) return;
        setLoader(true);
        const res = await commonServices.deleteUserByAdmin(payload);
        setLoader(false);
        if (res?.status === 200) {
            toast.success(res?.data?.message, commonStyles.toastSuccess);
            Navigate('/');
        }   
    };



    return (
        <>
            {loader && <Loader />}
            <Box sx={loginStyles.mainDiv}>
                <Grid container spacing={0}>
                    <Grid item xs={6} sx={loginStyles.mainLogoDiv}>
                        <Box sx={loginStyles.leftshadows}></Box>
                        <img srcSet={`${Image.tuneConnectLogo_400} 680w, ${Image.tuneConnectLogo} 1920w`} alt="logo" />
                    </Grid>
                    <Grid item xs={6} sx={loginStyles.mainLogoDiv}>
                        <Box sx={loginStyles.rightshadows}></Box>

                        <Box component={'form'} onSubmit={handleSubmit}>
                            <InputLabel sx={loginStyles.labelText}> One Time Password (OTP) </InputLabel>
                            <CustomTextField name="otp" helperText={error.otp}
                                value={data.otp} changeValue={setData} />
                            <Button type='submit' variant="contained" sx={loginStyles.themeButton}>Submit</Button>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </>
    )
}

export default Otp
