import React, { useEffect, useState } from 'react'
import { Box, Grid, InputLabel, MenuItem, FormControl, Select, Button } from '@mui/material';
import { loginStyles, commonStyles } from '../../Styles/commonStyles';
import { Image } from '../../Utils/imageImport';
import { CustomTextField } from '../../Utils/customField';
import { commonServices } from '../../Services/commonServices';
import { validateEmpty } from '../../Utils/validations';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from '../../Components/lotties';

const DeleteUser = () => {
    const Navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState({
        email: "",
        password: "",
    });

    const [data, setData] = useState({
        email: "",
        password: "",
    })

    const [age, setAge] = React.useState(101);

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [countryCode, setCountryCode] = React.useState([]);
    const getCountries = async () => {
        const res = await commonServices.getCountries();
        if (res?.status === 200) {
            setLoader(false);
            console.log(res?.data?.data?.rows);
            setCountryCode(res?.data?.data?.rows);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const countryCode = data.get('countryCode');
        let payload = {
            "mobileNumber": email,
            "countryCode": countryCode,
            "type": 1,
        }
        if (!validateEmpty(email, "email", error, setError)) return;

        const res = await commonServices.mobileLogin(payload);
        if (res?.status === 200) {
            setLoader(true);
            toast.success(res?.data?.message, commonStyles.toastSuccess);
            sessionStorage.setItem("userID", JSON.stringify(res?.data?.data?.userId));
            setTimeout(() => {
                Navigate('/otp')
            }, [1000])
        }

    };

    useEffect(() => {
        setLoader(true);
        getCountries();
    }, [])

    return (
        <>
            {loader && <Loader />}
            <Box sx={loginStyles.mainDiv}>
                <Grid container spacing={0}>
                    <Grid item xs={6} sx={loginStyles.mainLogoDiv}>
                        <Box sx={loginStyles.leftshadows}></Box>
                        <img srcSet={`${Image.tuneConnectLogo_400} 680w, ${Image.tuneConnectLogo} 1920w`} alt="logo" />
                    </Grid>
                    <Grid item xs={6} sx={loginStyles.mainLogoDiv}>
                        <Box sx={loginStyles.rightshadows}></Box>

                        <Box component={'form'} onSubmit={handleSubmit}>
                            <InputLabel sx={loginStyles.labelText}> Mobile Number </InputLabel>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ maxWidth: 100, pr: 1 }}>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            name="countryCode"
                                            label="Age"
                                            onChange={handleChange}
                                            sx={loginStyles.selectInput}
                                        >
                                            {countryCode.map((value, index) => (
                                                <MenuItem value={value.countryId} key={index}>
                                                    {value.phoneCode}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Box>
                                <CustomTextField name="email" helperText={error.email}
                                    value={data.email} changeValue={setData} />
                            </Box>

                            <Button type='submit' variant="contained" sx={loginStyles.themeButton}>Submit</Button>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </>
    )
}

export default DeleteUser
