import React, { useState } from 'react'
import { loginStyles, commonStyles } from '../../Styles/commonStyles';
import { Box, Grid, InputLabel, Typography, Button } from '@mui/material';
import { Image } from '../../Utils/imageImport';
import { CustomTextField2 } from '../../Utils/customField';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateEmpty } from '../../Utils/validations';
import { authServices } from '../../Services/authServices';
import { toast } from 'react-toastify';
import { Loader } from '../../Components/lotties';


const ResetPassword = () => {
    const locationData = useLocation();
    const [loader, setLoader] = useState(false);
    const path = locationData.pathname;
    const token = path.replace('/ResetPassword/', '');
    const Navigate = useNavigate();
    console.log(token, "token");
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("routeAccess", "true");

    const [errorMsg, setErrorMsg] = useState({
        password: "",
        cpassword: "",
    })

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const password = data.get('password');
        const cpassword = data.get('cpassword');
        if (!validateEmpty(password, "password", errorMsg, setErrorMsg)) return;
        if (!validateEmpty(cpassword, "cpassword", errorMsg, setErrorMsg)) return;
        if (password !== cpassword) {
            toast.error("Password and Confirm Password should be same", commonStyles.toastError);
            return;
        }
        else {
            setLoader(true);
            let payload = {
                password: password,
                token: token,
            }
            const res = await authServices.resetPassword(payload);
            if (res?.status === 200) {
                toast.success(res?.data?.message, commonStyles.toastSuccess);
                setTimeout(() => {
                    setLoader(false);
                    Navigate("/login");
                }, 2000);
            }
        }
    };

    return (
        <>
            <Box sx={loginStyles.mainDiv}>
                {loader && <Loader />}
                <Grid container spacing={0}>
                    <Grid item xs={6} sx={loginStyles.mainLogoDiv}>
                        <Box sx={loginStyles.leftshadows}></Box>
                        <img srcSet={`${Image.tuneConnectLogo_400} 680w, ${Image.tuneConnectLogo} 1920w`} alt="logo" />
                    </Grid>
                    <Grid item xs={6} sx={loginStyles.mainLogoDiv}>
                        <Box sx={loginStyles.rightshadows}></Box>
                        <Box component={'form'} onSubmit={handleSubmit}>
                            <Typography sx={loginStyles.heading}>Reset Password</Typography>
                            <InputLabel sx={loginStyles.labelText}> Create New Password </InputLabel>
                            <CustomTextField2 name="password" helperText={errorMsg.password} />
                            <InputLabel sx={loginStyles.labelText}> Confirm New Password </InputLabel>
                            <CustomTextField2 name="cpassword" helperText={errorMsg.cpassword} />
                            <Button type='submit' variant="contained" sx={loginStyles.themeButton}>Submit</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default ResetPassword
