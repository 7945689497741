// Icons.js
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import ReportIcon from '@mui/icons-material/Report';
import RateReviewIcon from '@mui/icons-material/RateReview';
import VerifiedIcon from '@mui/icons-material/Verified';
import DangerousIcon from '@mui/icons-material/Dangerous';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PublicIcon from '@mui/icons-material/Public';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import TuneIcon from '@mui/icons-material/Tune';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EastIcon from '@mui/icons-material/East';
import NewspaperRoundedIcon from '@mui/icons-material/NewspaperRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const Icons = {
    DashboardIcon,
    PeopleIcon,
    ReportIcon,
    RateReviewIcon,
    VerifiedIcon,
    DangerousIcon,
    PauseCircleIcon,
    ErrorOutlineIcon,
    BlockIcon,
    CancelIcon,
    TaskAltIcon,
    NotificationsIcon,
    ExpandMoreIcon,
    CreateIcon,
    DeleteOutlineIcon,
    PublicIcon,
    AddIcon,
    HelpOutlineIcon,
    Visibility,
    VisibilityOff,
    ArrowCircleLeftIcon,
    ArrowCircleRightIcon,
    ArrowDropDownIcon,
    LogoutIcon,
    TuneIcon,
    MoreVertIcon,
    KeyboardBackspaceIcon,
    NewspaperRoundedIcon,
    DeleteIcon,
    HighlightOffIcon,
    VisibilityIcon
}

export const IconsList = {
    KeyboardBackspaceIcon,
    EastIcon
}