import React, { useState } from 'react';
import { TextField, Paper, InputBase, IconButton, Button, Typography, Tooltip, Avatar } from '@mui/material';
import { Stack, Pagination, Box, Menu, MenuItem, Select, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { TableStyles, commonStyles } from '../Styles/commonStyles';
import SearchIcon from '@mui/icons-material/Search';
import { Icons } from '../Styles/Icons';
import { ApiConfig } from '../Config/config';
import { nameFormatter } from './functions';


// Define and export the custom TextField component
export const CustomTextField = ({ name, helperText, value, changeValue }) => {
  return (
    <TextField
      variant="outlined"
      size="large"
      sx={{ minWidth: '350px' }}
      placeholder='Enter here'
      margin="dense"
      name={name}
      value={value}
      helperText={helperText}
      error={helperText ? true : false}
      onChange={(e) => changeValue((prev) => ({ ...prev, [name]: e.target.value }))}
    />
  );
};



export const CustomTextField2 = ({ name, helperText }) => {
  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      size="large"
      sx={{ minWidth: '350px' }}
      placeholder='Enter here'
      margin="dense"
      name={name}
      helperText={helperText}
      error={helperText ? true : false}
    />
  );
};

export const CustomPassword = ({ name, helperText, value, changeValue }) => {

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <FormControl sx={{ mt: 1 }} fullWidth>
      <OutlinedInput
        id="outlined-basic"
        variant="outlined"
        name={name}
        value={value}
        placeholder='Enter here'
        helperText={helperText} // helper text is not workering
        error={helperText ? true : false}
        type={showPassword ? 'text' : 'password'}
        sx={{ backgroundColor: '#f0f0f0', borderRadius: '10px' }}
        onChange={
          (e) => changeValue((prev) => ({ ...prev, [name]: e.target.value }))}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Icons.Visibility /> : <Icons.VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
      />
      {helperText && <Typography sx={commonStyles.helperText}>{helperText}</Typography>}
    </FormControl>
  );
};

//Search Field
export function SearchInputField({ tablePagination, handleSearch }) {
  return (
    <Paper
      component="form"
      sx={{ display: 'flex', alignItems: 'center', width: 350, borderRadius: '50px' }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1, pl: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search google maps' }}
        value={tablePagination?.search}
        onChange={handleSearch}
      />
      <IconButton aria-label="menu">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

//Verified Button
export function VerifiedButton() {
  return (
    <Button
      variant="contained"
      size="large"
      sx={TableStyles.verifiedButton}
    >
      Verified
    </Button>
  );
}

//Pending Button
export function PendingButton() {
  return (
    <Button
      variant="contained"
      size="large"
      sx={TableStyles.pendingButton}
    >
      Pending
    </Button>
  );
}

//Declined Button
export function DeclinedButton() {
  return (
    <Button
      variant="contained"
      size="large"
      sx={TableStyles.delineButton}
    >
      Declined
    </Button>
  );
}

//Table Pagination
export function TablePagination({ tablePagination, setTablePagination }) {
  const handlePageChange = (e, value) => {
    setTablePagination({ ...tablePagination, currentPage: value });
  };
  return (
    <Box sx={{ float: 'right' }}>
      <Stack spacing={2}>
        <Pagination count={tablePagination?.totalPages}
          onChange={handlePageChange} variant="outlined" shape="rounded" />
      </Stack>
    </Box>
  );
}

//Basic Menu - Pending, Verified, Declined
export function BasicMenu({ status, handleOption, userId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={status === 0 ? TableStyles.pendingButton : status === 1 ? TableStyles.verifiedButton : TableStyles.delineButton}
      >
        {status === 0 ? "Pending" : status === 1 ? "Verified" : "Declined"}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => { handleOption(1, userId); handleClose() }}
          sx={commonStyles.verifiedButton} value={userId}>
          <Icons.VerifiedIcon />
          <Typography sx={commonStyles.menuItemText}> Verified </Typography>
        </MenuItem>
        {/* <MenuItem onClick={() => { handleOption(0, userId); handleClose() }} sx={commonStyles.pendingButton}>
          <Icons.PauseCircleIcon />
          <Typography sx={commonStyles.menuItemText}>Pending</Typography>
        </MenuItem> */}
        <MenuItem onClick={() => { handleOption(2, userId); handleClose() }} sx={commonStyles.delineButton}>
          <Icons.DangerousIcon />
          <Typography sx={commonStyles.menuItemText}>Declined</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
//Custom Tooltip
export function CustomTooltip({ children, title }) {
  return (
    <Tooltip title={title} arrow>
      {children}
    </Tooltip>
  );
}

export function ToolTipContent({ heading, title }) {
  return (
    <Box>
      <Typography component={"span"} sx={TableStyles.toolTipHeading}>{heading}</Typography>
      <Typography component={"span"} sx={TableStyles.toolTipText}>{title}</Typography>
    </Box>
  );
}
//Image & Title
export function ImageTitle({ url, title }) {
  const baseUrl = ApiConfig.baseUrl;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {url ? (
          <img src={baseUrl + url} alt="profile" style={TableStyles?.profileImg} />
        ) : (
          <Avatar />
        )}
      </Box>
      <Typography component={"span"} sx={{ ...commonStyles.userName }}>
        {title ? nameFormatter(title) : "Admin"}
      </Typography>
    </Box>
  );
}

//DropDown
export function DropDown({ handleChange, value }) {
  return (
    <Box>
      <FormControl  >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={handleChange}
          sx={{ mx: 1 }}
        >
          <MenuItem value={3}>All</MenuItem>
          <MenuItem value={1}>Verified</MenuItem>
          <MenuItem value={2}>Declined</MenuItem>
          <MenuItem value={0}>Pending</MenuItem>
          <MenuItem value={4}>Incompleted Profile</MenuItem>
          <MenuItem value={5}>Deleted</MenuItem>
          <MenuItem value={6}>Blocked</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
//Accordion
export function AccordionUsage({ faqData, editFaqFunc, deleteFaqFunc }) {
  return (
    <>
      {faqData?.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<Icons.ExpandMoreIcon sx={commonStyles.themeColor_lg} />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={commonStyles.accordianBox}
          >
            <div dangerouslySetInnerHTML={{ __html: item?.question }} />
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={commonStyles.flex_center_between}>
              <div dangerouslySetInnerHTML={{ __html: item?.answer }} />
              <Box>
                <Icons.CreateIcon sx={commonStyles.themeColor_lg} onClick={() => editFaqFunc(item)} />
                <Icons.DeleteOutlineIcon sx={commonStyles.dangerText} onClick={() => deleteFaqFunc(item)} />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>))}
    </>
  );
}
//Heading
export function Heading({ title }) {
  return (
    <Typography variant="h5" sx={commonStyles.heading}>
      {title}
    </Typography>
  );
}
//Heading
export function Heading2({ title }) {
  return (
    <Typography variant="h6" sx={commonStyles.heading}>
      {title}
    </Typography>
  );
}
//Social Button
export function SocialButton({ titleText, id, SendId, type }) {
  const [title, setTitle] = useState(titleText);

  const handleMouseEnter = () => {
    setTitle('Delete'); // Change this to whatever text you want on hover
  };

  const handleMouseLeave = () => {
    setTitle(titleText); // Reset to the original title
  };

  return (
    <>
      <Button sx={commonStyles.socialButton} onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} onClick={() => SendId(id, type)}>{title}</Button>
    </>
  );
}

//Add Button Plus
export function AddButtonPlus({ handleOpen }) {
  return (
    <Button sx={commonStyles.addBtn} onClick={handleOpen}>
      <Icons.AddIcon sx={{ fontSize: '2rem', color: '#fff' }} />
    </Button>
  );
}

const ITEM_HEIGHT = 48;
export default function LongMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = ['Profile', 'My account', 'Logout'];

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icons.MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
