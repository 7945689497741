import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../Assests/Lottie/Nodata2.json';
import loader from '../Assests/Lottie/loader.json';
import { Box } from '@mui/material';
import { commonStyles } from '../Styles/commonStyles';

export const NoData = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Box sx={commonStyles?.lottieDiv}>
      <Lottie options={defaultOptions} width={'35%'} height={'auto'} />
    </Box>
  )
};

export const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Box sx={{
      ...commonStyles.loaderDiv,
      ...commonStyles.flex_center_center,
    }}
    >
      <Lottie options={defaultOptions} width={'20%'} height={'auto'} />
    </Box>
  )
};
