import { ApiConfig } from '../Config/config';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
// import { cryptoClient } from '../Utils/functions';

let initialToken = ApiConfig.AuthToken;
let finalToken = null;

//Token Not Required API List
let notRequiredAPI = ["forgotPassword", "cookiePolicy", "termsAndConditions", "resetPassword", "privacyPolicy","getCountries","mobileLogin","deleteUserByAdmin", "subscribeEmailValidation","captchaVerification"];

export const Projectclient = axios.create({
    baseURL: ApiConfig.baseUrl,
    timeout: 10000,
    headers: {
        "Content-Type": "application/json",
    },
});

Projectclient.interceptors.request.use(async (request) => {
    let routeAccess = sessionStorage.getItem('routeAccess');
    let token = sessionStorage.getItem('token');
    let refreshToken = sessionStorage.getItem('refreshToken');

    if (routeAccess === "true" && (!notRequiredAPI.includes(request.url))) {
        finalToken = token;
        const isExpired = jwtDecode(token).exp * 1000 < Date.now();
        refreshTokenIfExpired(isExpired, refreshToken);
    } else {
        finalToken = initialToken;
    }
    //console.log("interceptor data", request);
    request.headers["Authorization"] = `${finalToken}`;
    return request;
})

async function refreshTokenIfExpired(isExpired, refreshToken) {
    if (isExpired) {
        try {
            const response = await axios.get(`${ApiConfig.baseUrl}/refreshToken`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": refreshToken,
                },
            });
            sessionStorage.setItem("token", response?.data?.data?.accessToken);
            sessionStorage.setItem("refreshToken", response?.data?.data?.refreshToken);
        } catch (error) {
            console.error("Error refreshing token:", error);
        }
    }
}

const Userapifunction = async (endpoint, data, method) => {
    const config = {
        method: method,
        url: `/${endpoint}`,
        data: method === "GET" ? {} : data, // Request data
    };
    try {
        const res = await Projectclient(config);
        return (res.data);
    } catch (err) {
        console.log(err);
        toast.error(err.message)
    }
};



export default Userapifunction;