import React, { useRef, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { promoImages } from '../../Utils/imageImport';
import { IconsList } from '../../Styles/Icons';
import { promoStyles } from '../../Styles/promoStyles';

const UsrFrdCarousal = () => {

  const sliderRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '60px',
    autoplay: true,
    autoplaySpeed: 2000, // Speed in milliseconds
    afterChange: (currentSlide) => {
      setSlideIndex(currentSlide);
    },
    responsive: [
      {
        breakpoint: 1024, // screen width in pixels
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600, // screen width in pixels
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px'
        }
      },
      {
        breakpoint: 480, // screen width in pixels
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px'
        }
      }
    ]
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="carousel-container">
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <IconsList.KeyboardBackspaceIcon onClick={handlePrev} sx={promoStyles.carousalArrow} />
        <IconsList.EastIcon onClick={handleNext} sx={promoStyles.carousalArrow} />
      </div>

      <div>
        <Slider ref={sliderRef} {...settings} style={{ overflow: 'hidden' }}>
          <div>
            <img
              srcSet={`
                ${promoImages.C1_small} 480w,
                ${promoImages.C1} 768w,
                ${promoImages.C1} 1024w,`
              }
              style={{ width: '100%' }}
              alt="Tuneconnect Carousal - 1" />
          </div>
          <div>
            <img
              srcSet={`
                ${promoImages.C3_small} 480w,
                ${promoImages.C3} 768w,
                ${promoImages.C3} 1024w,`
              }
              style={{ width: '100%' }}
              alt="Tuneconnect Carousal - 3" />
          </div>
          <div>
            <img
              srcSet={`
                ${promoImages.C2_small} 480w,
                ${promoImages.C2} 768w,
                ${promoImages.C2} 1024w,`
              }
              style={{ width: '100%' }}
              alt="Tuneconnect Carousal - 2" />
          </div>
         
          <div>
            <img
              srcSet={`
                ${promoImages.C4_small} 480w,
                ${promoImages.C4} 768w,
                ${promoImages.C4} 1024w,`
              }
              style={{ width: '100%' }}
              alt="Tuneconnect Carousal - 4" />
          </div>
          


        </Slider>
      </div>

    </div>
  );
};

export default UsrFrdCarousal;



