import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { commonServices } from '../../Services/commonServices';
import { Loader } from '../../Components/lotties';
import { commonStyles } from '../../Styles/commonStyles';
import { Heading } from '../../Utils/customField';
import { Image } from '../../Utils/imageImport';


const TermsConditions = () => {

    const [privacy, setPrivacy] = useState('');
    const [loader, setLoader] = useState(true);
    const getPrivacyData = async () => {

        const res = await commonServices.termsAndConditions();
        if (res?.status === 200) {
            setTimeout(() => {
                setLoader(false);
            }, 500)
            setPrivacy(res?.data?.data?.description);
        }
    }

    useEffect(() => {
        getPrivacyData();
    }, [])
    return (
        <>
            <Box sx={commonStyles.privacyMainDiv}>
                <Container maxWidth="lg">
                    <Box sx={[commonStyles.textCenter, commonStyles.pb1]}>
                        <img src={Image.sideNav_logo} alt="logo" />
                    </Box>
                    <Heading title="Terms & Conditions" />
                    {loader ? <Loader /> : <div dangerouslySetInnerHTML={{ __html: privacy }}></div>}
                </Container>
            </Box>
        </>
    )
}

export default TermsConditions
