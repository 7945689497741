export function validateEmpty(params, paramsName, errorMsg, setErrorMsg) {

  if (params === "") {
      setErrorMsg(prevState => ({ ...prevState, [paramsName]: "This field is required" }));
      return false;
  } else {
      setErrorMsg(prevState => ({ ...prevState, [paramsName]: null }));
      return true;
  }
}
