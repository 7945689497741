import { Projectclient } from "../Axios/client";
import { toast } from "react-toastify";
import { commonStyles } from "../Styles/commonStyles";

export const authServices = {
    login: async (data) => {
        try {
            const res = await Projectclient.post("adminLogin", data);
            return (res);
        } catch (err) {
            toast.error(err?.response?.data?.message, commonStyles.toastError);
            console.log(err);
        }
    },
    forgotPassword: async (data) => {
        try {
            const res = await Projectclient.post("forgotPassword", data);
            return (res);
        } catch (err) {
            toast.error(err?.response?.data?.message, commonStyles.toastError);
            console.log(err);
        }
    },

    resetPassword: async (data) => {
        try {
            const res = await Projectclient.post("resetPassword", data);
            return (res);
        } catch (err) {
            toast.error(err?.response?.data?.message, commonStyles.toastError);
            console.log(err);
        }
    }
}
