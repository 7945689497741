import React, { useEffect, useState } from 'react';
import { commonServices } from '../../Services/commonServices';
import { Loader } from '../../Components/lotties';
import { commonStyles } from '../../Styles/commonStyles';
import { Box, Container } from '@mui/material';
import { Heading } from '../../Utils/customField';
import { Image } from '../../Utils/imageImport';
import parse from 'html-react-parser';

const CookiePolicy = () => {
    const [cookie, setCookie] = useState('');
    const [loader, setLoader] = useState(false);
    const getCookieData = async () => {
        const res = await commonServices.cookiePolicy();
        if (res?.status === 200) {
            setTimeout(() => {
                setLoader(false);
            }, 500)
            setCookie(res?.data?.data?.description);
        }
    }
    useEffect(() => {
        getCookieData();
    }, [])

    return (
        <>
            <Box sx={commonStyles.privacyMainDiv}>
                <Container maxWidth="lg">
                    <Box sx={[commonStyles.textCenter, commonStyles.pb1]}>
                        <img src={Image.sideNav_logo} alt="logo" />
                    </Box>
                    <Heading title="Cookie Policy" />
                    {loader ? <Loader /> : parse(cookie)}
                </Container>
            </Box>
        </>
    )
}

export default CookiePolicy
